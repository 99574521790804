import { createI18n } from 'vue-i18n'
// import { nextTick } from 'vue'
import axios from 'axios'

export const loadedLanguages = ['pl'];

export const SUPPORT_LOCALES = ['en', 'pl']

// import en from '@/assets/locales/en.json'
import pl from '@/assets/locales/pl.json'


const i18n = createI18n({
    locale: 'pl',
    fallbackLocale: 'en',
    messages: {
        'pl': pl,
        // 'en': en
    }
});

export function setI18nLanguage(lang) {
    i18n.global.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    
    return lang
}

export async function loadLanguageAsync(lang) {

    console.debug(i18n.global);
    // If the same language
    if (i18n.global.locale === lang) {
        console.debug('Lang set');
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        console.debug('Lang loaded');
        return Promise.resolve(setI18nLanguage(lang))
    }

    if(SUPPORT_LOCALES.includes(lang)){
        console.debug('Loading lang: '+lang);
        // If the language hasn't been loaded yet

        // If the language hasn't been loaded yet
        let messages = null;

        messages = await import(/* webpackChunkName: "i18n-[lang]" */ '@/assets/locales/'+lang+'.json')
        
        if (messages != null) {
            i18n.global.setLocaleMessage(lang, messages.default)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
      
        // return nextTick()
    }

    return Promise.resolve(false)
}

export default i18n