

<template v-cloak>
  <Notification v-if="currentRouteName != 'Error'" />
  <Header v-if="currentRouteName != 'Error'"/>
    <!-- <transition name="fade"> -->
  <router-view />
    <!-- </transition> -->
  <Footer v-if="currentRouteName != 'Error'" v-once />
</template>
<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import Notification from "@/components/layout/Notification.vue";
export default {
  name: "App",
  components: {
    Header, 
    Footer, 
    Notification
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    }
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
};
</script>

<style lang="scss">
[v-cloak] {
  display: none !important;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}
body {
  background: #eee;
}
#header,
.main {
  background: #fff;
}
body,
#app {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  // overflow: hidden;
}
</style>
