<template>
  <div class="nav-tab">
    <router-link :to="tabData.path" v-html="$t(tabData.name)" ></router-link>
  </div>
</template>

<script>
export default {
  name: "NavTab",
  props: ["tabData",'close_nav'],
};
</script>

<style lang="scss">
@import "@/assets/styles/global.scss";
.nav-tab {
  // @include lg-max{
  //     &:nth-last-of-type(1){
  //       & a::after{
  //         display: none;
  //       }
  //     }
  // }
  a {
      line-height: 5rem;
      padding: 5px;
      margin: 0 30px;
      font-size: 2rem;

    @include lg-max {
      line-height: 60px;
      padding: 5px;
      margin: 0 30px;
      font-size: 1.3rem;
      text-transform: uppercase;
      font-family: 'Fira Code', monospace;

      // position: relative;


      // &::after{
      //   content: '';
      //   position: absolute;
      //   width: 100px;
      //   height: 0;
      //   border-bottom: 1px dotted #ccc;
      //   bottom: -10px;
      //   left: 0;
      // }
    }
    @include lg {
      line-height: 60px;
      padding: 5px;
      margin: 0 30px;
      font-size: 20px;
    }
    @include xl {
      line-height: 60px;
      padding: 5px;
      margin: 0 20px;
      font-size: 20px;
    }

    text-decoration: none;
    color: $def_color;
    
    position: relative;

    &.router-link-exact-active {
    //   background: red;
      
    }
    // &.router-link-exact-active

    &:hover {
      // color: $accent_color;
      color: #000;
      // border-bottom: 2px solid $accent_color;
      // animation: pulse .1s ease-in;
      
      background: -webkit-linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: gradient 10s ease infinite;
      background-size: 400% 400%;
      
      
      &::after{
        content: "";
        position: absolute;
        left: 0;
        top: calc(50% + 2px);
        right: 0;
        bottom: 0;
        height: 2px;
        
        background: -webkit-linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
        animation: gradient 10s ease infinite;
        animation: fade-in 0.3s ease-in;
        background-size: 400% 400%;
      }
      
      @keyframes fade-in {
        0% {
          width: 10%;
        }
        50% {
          width: 50%;
        }
        100% {
          width: 100%;
        }
      }
    }
  }
}
</style>