import { createStore } from 'vuex'
import axios from 'axios'
// import router from './../router/index'

// const headers = [
//   { 'Access-Control-Allow-Origin': '*' },
//   { 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE' },
//   { 'Access-Control-Allow-Headers': 'Origin, Content-Type, Accept, Authorization, X-Request-With' },
// ];

const api = axios.create({
  'baseURL': process.env.VUE_APP_BASE_URL,
  // headers: {Accept: "application/json", "Content-Type": "application/json"}

});

// api.interceptors.response.use(null, error => {
//   let path = '/down';
//   router.push(path);
//   return Promise.reject(error);
// });

export default createStore({
  devtools: true,
  state: {
    error: [],
    message: { status: null, error: null, loading: null, loaded: null, response: null },
    docs: { status: null, error: null, loading: null, loaded: null, response: null },
    file_upload: { status: null, error: null, loading: null, loaded: null, response: null },
    url_shorter: { status: null, error: null, loading: null, loaded: null, response: null },
    notifications: [
      // {
      //   name: "Infomacja",
      //   content: "Wysłano poprawnie wiadomość.",
      //   time: Date.now(),
      //   type: 'info'
      // }
    ],
  },
  mutations: {
    SET_MESSAGE_RESPONSE(state, response) {
      if (response.status != 200) {
        state.message.loaded = false;
        state.message.status = 'Fail';

        state.notifications.push({
          name: "Błąd",
          content: response.massege,
          time: Date.now(),
          type: 'error'
        })
      }

      state.message.loading = false;
      state.message.loaded = true;

      if (state.message.response != response.data) {
        state.message.response = response.data;
        state.message.status = 'Send';

        state.notifications.push({
          name: "Infomacja",
          content: "Wysłano poprawnie wiadomość.",
          time: Date.now(),
          type: 'info'
        })
      }

    },
    SET_DOCS_RESPONSE(state, response) {
      if (response.status != 200) {
        state.docs.loaded = false;
        state.docs.status = 'Fail';

        state.notifications.push({
          name: "Błąd",
          content: response.e_message,
          time: Date.now(),
          type: 'error'
        })
      }

      state.docs.loading = false;
      state.docs.loaded = true;

      if (state.docs.response != response.data) {
        state.docs.response = response.data;
        state.docs.status = 'Send';

        state.notifications.push({
          name: "Infomacja",
          content: "Pobrano pomyślnie dokument",
          time: Date.now(),
          type: 'info'
        })
      }

    },
    SET_FILE_RESPONSE(state, response) {
      if (response.status != 200) {
        state.file_upload.loading = false;
        state.file_upload.loaded = false;
        state.file_upload.status = 'Fail';

        state.notifications.push({
          name: "Błąd",
          content: response.data.e_message,
          time: Date.now(),
          type: 'error'
        })
      }

      state.file_upload.loading = false;
      state.file_upload.loaded = true;

      if (state.file_upload.response != response.data) {
        state.file_upload.response = response.data;
        state.file_upload.status = 'Send';

        state.notifications.push({
          name: "Infomacja",
          content: "Gratulacje! Wysłano poprawnie plik na serwer.",
          time: Date.now(),
          type: 'info'
        })
      }
    },
    SET_SHORTER_RESPONSE(state, response) {
      if (response.status != 200) {
        state.url_shorter.loading = false;
        state.url_shorter.loaded = false;
        state.url_shorter.status = 'Fail';

        state.notifications.push({
          name: "Błąd",
          content: response.data.e_message,
          time: Date.now(),
          type: 'error'
        })

      }

      state.url_shorter.loading = false;
      state.url_shorter.loaded = true;

      if (state.url_shorter.response != response.data) {
        state.url_shorter.response = response.data;
        state.url_shorter.status = 'Send';
        state.notifications.push({
          name: "Infomacja",
          content: "Gratulacje! Pobrano krótki link.",
          time: Date.now(),
          type: 'info'
        })
      }
    },
    SET_NEW_MESSAGE(state, payload) {
      state.notifications.push({
        name: payload.name,
        content: payload.content,
        time: Date.now(),
        type: payload.type ? payload.type : 'info'
      })
    },
    SET_START_LOADING(state, payload) {
      state[payload.name].loading = true;
      state[payload.name].loaded = false;
      state[payload.name].status = payload.status;
    },
    SET_CLEAR(state, payload) {
      state[payload.name].loading = false;
      state[payload.name].loaded = false;
      state[payload.name].status = null;
      state[payload.name].response = null;
    }
  },
  actions: {
    async sendMessage({ commit }, payload) {
      commit('SET_START_LOADING', { name: 'message', status: 'Sending' });
      await api.post(process.env.VUE_APP_API_URL + '/message', payload, { headers: { Accept: "application/json", "Content-Type": "application/json" } }).then((response) => {
        commit('SET_MESSAGE_RESPONSE', response);
      });
    },
    async sendFile({ commit }, payload) {
      commit('SET_START_LOADING', { name: 'file_upload', status: 'Sending' });

      let formData = new FormData();
      formData.append('file', payload.file);
      formData.append('email', payload.email);
      await api.post(process.env.VUE_APP_API_URL + '/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        commit('SET_FILE_RESPONSE', response);
      });
    },
    async getDocs({ commit }, payload) {
      commit('SET_START_LOADING', { name: 'docs', status: 'Downloading' });

      await api.post(process.env.VUE_APP_API_URL + '/docs', {
        id: payload.id,
        hash: payload.hash,
      }, { headers: { Accept: "application/json", "Content-Type": "application/json" } }).then((response) => {
        commit('SET_DOCS_RESPONSE', response);
      });
    },
    async getShorterUrl({ commit }, payload) {
      commit('SET_START_LOADING', { name: 'url_shorter', status: 'Sending' });

      await api.post(process.env.VUE_APP_API_URL + '/url-shorter', payload, { headers: { Accept: "application/json", "Content-Type": "application/json" } }).then((response) => {
        commit('SET_SHORTER_RESPONSE', response);
      });
    },
    async notify({ commit }, payload) {
      commit('SET_NEW_MESSAGE', payload);
    },
    async clear({ commit }, payload) {
      commit('SET_CLEAR', payload);
    }
  },
  modules: {
  }
})
