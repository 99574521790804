import { createRouter, createWebHistory } from 'vue-router'

import { routes } from './routes.js'

import { loadLanguageAsync } from '@/i18n'


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const lang = navigator.language.split('-')[0];

    document.title = to.meta.title
    document.lang = lang

    console.debug(lang)

    await loadLanguageAsync(lang)

    next()
})

export default router