<template>
  <div id="notification-container">
    <div
      class="item-wrapper"
      v-for="notification in notifications"
      :key="notification.name + ' ' + notification.time"
      :class="notification.info"
    >
        <div class="item" :class="now - notification.time > 5000 ? 'item-out':'item-in'" v-if="now - notification.time < 5500">
      {{ notification.content }}
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notification",
  data() {
    return {
      now: null
    }
  },
  created() {
    var self = this;
      setInterval(function () {
         self.now = Date.now()
      }, 500)
  },
  computed: {
    notifications() {
      return this.$store.state.notifications;
    }
  },
  
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/global.scss";
#notification-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  z-index: 1001;
  .item {
  
    filter: drop-shadow(0 0 8px rgb(157, 157, 157));
    color: #fff;
    background: #000;
    border-radius: 2px;
    
    margin: 0 8vw;
    padding: 15px 30px;
    margin-top: 10vh;
    
    @include xl {
    
    padding: 5px 20px;
    margin-top: 30px;
        }

    font-size: 1.2rem;
    font-weight: 500;

    z-index: 100;
    
    // font-family: Helvetica, Arial, sans-serif;;
    font-family: 'Fira Code', monospace;
    
    
    &-in{
      animation: fade-in 0.5s ease-in;
    
    }
    &-out{
      animation: fade-out 0.5s ease-out;
      
    }
    
    @keyframes fade-out {
        0% {
          transform: translateY(0);
        }
        90% {
          transform: translateY(-50vh);
        }
        100% {
          transform: translateY(-100vh);
        }
      }
    @keyframes fade-in {
        0% {
          transform: translateY(-10vh);;
        }
        50% {
          transform: translateY(-5vh);
        }
        // 90% {
        //   transform: translateY(1vh);
        // }
        100% {
          transform: translateY(0);
        }
      }
  }
}
</style>