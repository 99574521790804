const publicPath = 'https://www.dolega.me/';
export const routes = [
  {
    path: '/',
    name: 'Home',
    renderSpa: true,
    component: () => import(/* webpackChunkName: "home" */ '@/pages/Home.vue'),
    meta: {
      title: 'Mateusz Dołęga - Programista',
      canonical: publicPath + '',
      sitemap: {
        priority: 1,
        changefreq: 'daily',
      }
    }

  },
  {
    path: '/cv',
    name: 'Cv',
    renderSpa: true,
    component: () => import(/* webpackChunkName: "cv" */ '@/pages/Cv'),
    meta: {
      title: 'CV - dolega.me',
      canonical: publicPath + 'cv',
      sitemap: {
        priority: 0.9,
        changefreq: 'monthly',
      }
    }
  },
  {
    path: '/portfolio',
    name: 'Work',
    renderSpa: true,
    component: () => import(/* webpackChunkName: "work" */ '@/pages/Work.vue'),
    meta: {
      title: 'Portfolio - dolega.me',
      canonical: publicPath + 'portfolio',
      sitemap: {
        priority: 0.9,
        changefreq: 'monthly',
      }
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    renderSpa: true,
    component: () => import(/* webpackChunkName: "contact" */ '@/pages/Contact'),
    meta: {
      title: 'Contact me - dolega.me',
      canonical: publicPath + 'contact',
      sitemap: {
        lastmod: 'February 2020',
        priority: 0.9,
        changefreq: 'monthly',
      }
    }
  },
  {
    path: '/file-share',
    name: 'FileShare',
    renderSpa: false,
    component: () => import(/* webpackChunkName: "file-share" */ '@/pages/FileShare.vue'),
    meta: {
      title: 'File share - dolega.me',
      canonical: publicPath + 'file-share',
      sitemap: { ignoreRoute: true }
    }
  },
  {
    path: '/url-shorter',
    name: 'UrlShorter',
    renderSpa: false,
    component: () => import(/* webpackChunkName: "url-shorter" */ '@/pages/UrlShorter.vue'),
    meta: {
      title: 'Url shorter - dolega.me',
      canonical: publicPath + 'url-shorter',
      sitemap: {
        lastmod: 'November 2020',
        priority: 0.9,
        changefreq: 'monthly',
      }
    }
  },
  {
    path: '/docs',
    name: 'Docs',
    renderSpa: false,
    component: () => import(/* webpackChunkName: "docs" */ '@/pages/Docs.vue'),
    meta: {
      title: 'Dokumenty - dolega.me',
      canonical: publicPath + 'docs',
      sitemap: {
        lastmod: 'September 2020',
        priority: 0.9,
        changefreq: 'monthly',
      }
    }
  },
  {
    path: '/down',
    name: 'Down',
    renderSpa: false,
    component: () => import(/* webpackChunkName: "server-down" */ '@/pages/ServerDown.vue'),
    meta: {
      title: 'Wystąpił błąd',
      canonical: publicPath + 'down',
      sitemap: {
        lastmod: 'September 2020',
        priority: 0.5,
        changefreq: 'monthly',
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error',
    renderSpa: false,
    component: () => import(/* webpackChunkName: "server-down" */ '@/pages/Error.vue'),
    props: { errorCode: 404, errorMessage: 'Page not found...' },
    meta: {
      title: 'Wystąpił błąd',
      sitemap: {
        lastmod: 'February 2020',
        priority: 0.5,
        changefreq: 'monthly',
      }
    }
  }
];