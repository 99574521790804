import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'

import VueLazyLoad from 'vue3-lazyload'

const app = createApp(App)

app.mixin({
    methods: {
        webp: function(path) {
            return process.env.NODE_ENV == 'production' && path.slice(path.length - 4, path.length) != 'webp' ? (path + '.webp') : path;
        },
    },
})

app.use(store)
app.use(router)
app.use(i18n)
app.use(VueLazyLoad)

app.mount('#app')